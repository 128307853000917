<template>
  <div id="app">
    <div class="david"></div>
    <p>
      <strong>
        <a href="https://github.com/jakowenko" target="_blank"
          >David Jakowenko</a
        >
      </strong>
      is a {{ age }} year old full-stack developer who lives in Clawson,
      Michigan.
    </p>
    <div class="info">
      <ul>
        <li>Interests</li>
        <li>Cloud computing</li>
        <li>Web development</li>
        <li>UI/UX</li>
      </ul>
      <ul>
        <li>Work</li>
        <li>
          <a href="https://24g.com" target="_blank">24G</a>
        </li>
        <li><strong>Director of DevOps</strong></li>
        <li>February 2021 &ndash; Present</li>
        <li><strong>Director of Programming</strong></li>
        <li>September 2011 &ndash; February 2021</li>
      </ul>
      <ul>
        <li>Education</li>
        <li>Oakland University</li>
        <li>Bachelor of Science, Biology</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data: () => {
    return {
      age: getAge("1988/02/08"),
    };
  },
};

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background: #f2f2f2;
}

.david {
  width: 150px;
  height: 150px;
  background: url(./assets/img/david.jpg);
  background-size: cover;
  background-position: 55% center;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 40px;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);
}

p {
  line-height: 150%;
  @media only screen and (max-width: 850px) {
    width: 90%;
    margin: auto;
  }
}

a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.info {
  margin-top: 35px;

  ul {
    margin: 0;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    margin: 0 25px;
    list-style: none;
    line-height: 150%;
    padding: 0;

    @media only screen and (max-width: 850px) {
      width: 55%;
      display: block;
      text-align: center;
      margin: auto;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul > li:first-child {
    font-weight: bold;
    border-bottom: 1px solid #2c3e50;
    margin-bottom: 10px;
    font-size: 16px;
  }
}
</style>
